<template>
  <div>
    <h1>Users</h1>
  </div>
</template>

<script>
export default {
  name: "UsersView"
}
</script>

<style scoped>

</style>
